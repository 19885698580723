import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
  isNeedToSendCallPermission: false,
  callWidget: {
    isShow: false,
    user: {
      avatar: '',
      fullname: '',
      phone: '',
      roomId: '',
    },
    session: '',
    event: '',
    status: '',
    id: '',
  },
})

export const getters = {
  getField,
}

export const mutations = {
  updateField,
  UPDATE_CALL_WIDGET(state, payload) {
    state.callWidget = {
      ...state.callWidget,
      ...payload,
    }
  },
  UPDATE_CALL_PERMISSION(state, payload) {
    state.isNeedToSendCallPermission = payload
  },
}
